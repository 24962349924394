<template>
  <div>
    <h1>Adicionar CTe</h1>
    <h1 v-if="already" class="red--text">ESTE CTE JÁ EXISTE!!!</h1>

    <v-btn
      @click="$router.push('/ctes')"
      color="error"
      class="mb-2"
      title="Voltar"
    >
      <v-icon> mdi-keyboard-return </v-icon>
    </v-btn>

    <v-btn
      v-if="$auth.isAuthorized('manager')"
      color="primary"
      class="mb-2 mx-2"
      @click="$router.push('/enviar-arquivo-ctes')"
      >Enviar Arquivo</v-btn
    >

    <v-switch v-model="hasToSearchCte" label="Procurar CTe?"></v-switch>

    <v-progress-linear color="purple" indeterminate v-if="saving" />
    <v-form :disabled="searching" ref="formCte" @submit.prevent="saveCte()">
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
            label="CTe*"
            :rules="requiredRules"
            v-model="cte.number"
            @focusout="searchCte"
            :loading="searching"
            ref="cteNumber"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="NFe*"
            :rules="requiredRules"
            v-model="cte.nf"
            ref="nfField"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            :items="partners"
            item-text="name"
            item-value="id"
            :loading="loading"
            label="Parceira*"
            :rules="requiredRules"
            v-model="cte.partner_id"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            type="date"
            label="Data de chegada*"
            :rules="requiredRules"
            v-model="cte.arrival"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            type="date"
            label="Previsão de entrega*"
            :rules="requiredRules"
            v-model="cte.dpe"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <CustomerInput
            ref="senderInput"
            @set_customer_id="set_sender_id($event)"
            InputLabel="Remetente"
            inputName="sender"
          />
        </v-col>
        <v-col cols="12" md="6">
          <CustomerInput
            ref="recipientInput"
            @set_customer_id="set_recipient_id($event)"
            InputLabel="Destinatário"
            inputName="recipient"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
            label="Volumes*"
            v-model="cte.qty"
            :rules="requiredRules"
            ref="qtyField"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Peso*"
            v-model="cte.weigth"
            :rules="requiredRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field label="Valor NFe" v-model="cte.nf_value"></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Valor frete*"
            :rules="requiredRules"
            v-model="cte.freight_value"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-if="shouldInsertIcms"
            label="ICMS"
            v-model="cte.icms_value"
            :rules="requiredRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-if="shouldInsertToll"
            label="Pedágio"
            v-model="cte.toll"
            :rules="requiredRules"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field label="TDA" v-model="cte.tda"></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field label="TDE" v-model="cte.tde"></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field label="Taxas" v-model="cte.fees"></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field label="Descarga" v-model="cte.discharge"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn type="submit" color="secondary" block :loading="saving"
            >Salvar</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import moment from "moment";
import http from "@/modules/http";
import CustomerInput from "../components/CustomerInput.vue";
import partnersModule from "@/modules/partners";

export default {
  components: {
    CustomerInput,
  },
  watch: {
    "cte.partner_id"() {
      this.getCommissionRules();
    },
  },
  data() {
    return {
      hasToSearchCte: true,
      already: false,
      searching: false,
      hasAlternativeAddress: false,
      isLoadingCities: false,
      cities: [],
      saving: false,
      partners: [],
      loading: false,
      requiredRules: [(v) => !!v || "Este campo é obrigatório"],
      shouldInsertIcms: false,
      shouldInsertToll: false,
      cte: {
        arrival: new Date().toJSON().slice(0, 10),
        dpe: "",
        number: "",
        nf: "",
        partner_id: "",
        bar_code: "",
        sender_id: "",
        recipient_id: "",
        qty: "",
        weigth: "",
        nf_value: "",
        freight_value: "",
        tda: 0,
        tde: 0,
        fees: 0,
        discharge: 0,
        alternative_city_id: "",
        alternative_address: "",
        palletized: false,
        icms_value: null,
        toll: "",
      },
    };
  },
  mounted() {
    this.getPartners();

    this.$refs.cteNumber.focus();
  },
  methods: {
    getCommissionRules() {
      this.shouldInsertIcms = false;
      this.shouldInsertToll = false;
      this.$http.get(`api/partners/${this.cte.partner_id}`).then((partner) => {
        partner.data.variations.map(v => {
          if (v.icms_discount) {
            this.shouldInsertIcms = true;
          }
          if (v.toll_discount) {
            this.shouldInsertToll = true;
          }
        })
      });
    },
    searchCustomer(input, name) {
      let lowName = name.toLowerCase();
      if (input == "recipient") {
        http
          .get("api/customers?customer_name=" + lowName)
          .then((res) => {
            if (res.data.length > 1) {
              alert(
                "ATENÇÃO! Foi encontrado mais de 1 cliente, confira o CNPJ do remetente e do destinatário!"
              );
            }
            let cnpj = res.data.length > 0 ? res.data[0].cnpj : "";
            this.$refs.recipientInput.findedCustomer(cnpj, name);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (input == "sender") {
        http
          .get("api/customers?customer_name=" + lowName)
          .then((res) => {
            let cnpj = res.data.length > 0 ? res.data[0].cnpj : "";
            this.$refs.senderInput.findedCustomer(cnpj, name);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async searchCte() {
      if (this.cte.number && this.cte.number.length > 0) {
        let response = null;
        this.searching = true;

        await http
          .get("api/ctes?number=" + this.cte.number)
          .then((res) => {
            if (res.data.id) {
              response = res.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });

        if (response === null && this.hasToSearchCte) {
          http
            .get("api/pre-cte?cte=" + this.cte.number)
            .then((res) => {
              this.searching = false;
              if (res.data.number) {
                this.cte.number = res.data.number;
                this.cte.dpe = res.data.dpe;
                this.cte.partner_id = res.data.partner_id;
                this.cte.arrival = res.data.arrival;
                this.cte.freight_value = res.data.freight_value;
                this.cte.nf = res.data.nf;
                this.cte.nf_value = res.data.nf_value;
                this.cte.qty = res.data.qty;
                this.cte.weigth = res.data.weigth;
                this.searchCustomer("sender", res.data.sender);
                this.searchCustomer("recipient", res.data.recipient);
              }

              this.$nextTick(() => {
                this.$refs.nfField.focus();
              });
            })
            .catch((err) => {
              this.searching = false;
              console.log(err);
            });
        } else {
          this.searching = false;
          if (response) {
            this.already = true;
          } else {
            this.already = false;
          }
        }
      }
    },
    getCities() {
      this.isLoadingCities = true;
      http
        .get("api/cities")
        .then((res) => {
          this.cities = res.data;
          this.isLoadingCities = false;
        })
        .catch((e) => {
          console.log(e);
          this.$side({
            type: "error",
            msg: "Erro ao carregar as cidades",
            duration: 4000,
          });
          this.isLoadingCities = false;
        });
    },
    set_sender_id(customer) {
      this.cte.sender_id = customer.id;
      if (!this.cte.recipient_id) {
        this.$refs.recipientInput.getFocus();
      } else {
        this.$refs.qtyField.focus();
      }
    },
    handleCityFee(fees) {
      if (!fees) {
        return false;
      }
      let fee = fees.find((fee) => fee.partner_id === this.cte.partner_id);
      return fee;
    },
    set_recipient_id(customer) {
      this.cte.tda = 0;
      this.cte.tde = 0;
      this.cte.fees = 0;

      this.cte.recipient_id = customer.id;
      this.cte.tda += parseFloat(customer.tda);
      this.cte.tde += parseFloat(customer.tde);
      this.cte.fees += parseFloat(customer.fees);

      let findedFee = this.handleCityFee(customer.cities?.city_fees);

      if (findedFee) {
        this.cte.tda += parseFloat(findedFee?.value);
      }

      this.$refs.qtyField.focus();
    },
    async getPartners() {
      this.loading = true;
      this.partners = await partnersModule();
      this.loading = false;
    },
    async saveCte() {
      if (this.$refs.formCte.validate()) {
        if (this.cte.sender_id.length < 1) {
          this.$toast.error("O Remetente deve ser preenchido!");
        } else if (this.cte.recipient_id.length < 1) {
          this.$toast.error("O Destinatário deve ser preenchido!");
        } else {
          this.saving = true;
          let data = this.$functions.removeNull(this.cte);
          data = this.$functions.removeEmpty(data);

          try {
            await http.post("api/ctes", data);
            this.$toast.success("CTe Salvo");
            this.cleanForm();
          } catch (error) {
            if (error.response) {
              let errors = Object.entries(error.response.data.errors);

              if (errors) {
                errors.forEach((e) => {
                  this.$toast.error(e[1][0]);
                });
              }
            }
          }

          this.saving = false;
        }
      }
    },
    cleanFields() {
      this.cte.arrival = new Date().toJSON().slice(0, 10);
      this.cte.dpe = "";
      this.cte.number = "";
      this.cte.nf = "";
      this.cte.sender_id = "";
      this.cte.recipient_id = "";
      this.cte.qty = "";
      this.cte.weigth = "";
      this.cte.nf_value = "";
      this.cte.freight_value = "";
      this.cte.tda = 0;
      this.cte.tde = 0;
      this.cte.fees = 0;
      this.cte.discharge = 0;
      this.cte.icms_value = null;
      this.cte.toll = null;
    },
    cleanForm() {
      this.cleanFields();
      this.cte.recipient_id = "";
      this.cte.sender_id = "";
      this.$refs.formCte.resetValidation();
      this.$refs.senderInput.cleanCustomer();
      this.$refs.recipientInput.cleanCustomer();
      this.$refs.cteNumber.focus();
    },
  },
};
</script>
