<template>
  <div>
    <v-text-field
      :label="InputLabel + '*'"
      append-outer-icon="mdi-account-search"
      @click:append-outer="searching = true"
      append-icon="mdi-plus"
      @click:append="adding = true"
      v-model="customer.cnpj"
      @focusout="getCustomer()"
      :hint="customer.name"
      persistent-hint
      :loading="loading"
      :name="inputName"
      :rules="[
        (v) => !!v || 'Campo obrigatório',
        (v) => (!!v && v.length >= 11) || 'Mínimo 11 números',
        (v) => (!!v && v.length < 15) || 'Máximo 14 números',
      ]"
      ref="customer"
    ></v-text-field>

    <v-dialog v-model="searching">
      <SearchCustomer @set_customer="setCustomer($event)" />
    </v-dialog>

    <div v-if="adding">
      <v-dialog v-model="adding">
        <v-card>
          <v-card-title>Novo cliente</v-card-title>
          <v-card-text>
            <CreateCustomer
              :name="name"
              :onCte="true"
              @set_cnpj="setCustomer($event)"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import http from "@/modules/http";
import SearchCustomer from "./SearchCustomer.vue";
import CreateCustomer from "../../customers/components/Form.vue";
export default {
  components: {
    SearchCustomer,
    CreateCustomer,
  },
  props: {
    InputLabel: {
      type: String,
      default: "Cliente",
    },
    inputName: {
      type: String,
      default: "sender",
    },
  },
  data() {
    return {
      loading: false,
      searching: false,
      adding: false,
      customer: {
        id: "",
        name: "",
        cnpj: "",
      },
      name: "",
    };
  },
  methods: {
    getFocus() {
      this.$refs.customer.focus();
    },
    cleanCustomer() {
      this.customer = {
        id: "",
        name: "",
        cnpj: "",
      };
    },
    setCustomer(customer) {
      this.customer = customer;
      this.searching = false;
      this.adding = false;
      this.name = "";
      this.$emit("set_customer_id", customer);
    },
    findedCustomer(customer, name) {
      this.name = name;
      this.customer.cnpj = customer;
      this.getCustomer();
    },
    getCustomer() {
      if (this.customer.cnpj) {
        if (
          (this.customer.cnpj.length > 1 && this.customer.cnpj.length < 11) ||
          this.customer.cnpj.length > 14
        ) {
          this.$toast.error("CNPJ/CPF inválido!");
        } else if (
          this.customer.cnpj.length >= 11 &&
          this.customer.cnpj.length <= 14
        ) {
          this.loading = true;
          http
            .get("api/customers?cnpj=" + this.customer.cnpj)
            .then((res) => {
              this.loading = false;
              if (res.data.data.length >= 1) {
                this.setCustomer(res.data.data[0]);
              } else {
                this.$store.dispatch("set_current_cnpj", this.customer.cnpj);
                this.$toast.error("Cliente não encontrado");
              }
            })
            .catch((e) => {
              this.loading = false;
              this.$toast.error("Erro ao buscar cliente");
            });
        }
      }
    },
  },
};
</script>
